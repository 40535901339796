import React from "react";

const ClientOnly = ({ children, ...delegated }) => {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        return <div></div>;
    }
    return <div {...delegated}>{children}</div>;
};

export default ClientOnly;
